<template>
  <v-navigation-drawer
    bottom
    color="transparent"
    fixed
    height="auto"
    overlay-color="secondary"
    overlay-opacity=".6"
    temporary
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-list color="#212121" shaped>
      <v-list-item
        v-for="name in items"
        :key="name"
        :to="{ name }"
        :exact="name === 'Home'"
      >
        <v-list-item-content class="white--text">
          <v-list-item-title v-text="name" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "HomeDrawer",

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
